import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, takeWhile, tap } from 'rxjs/operators';
import { AuthUser } from 'src/app/auth/auth.models';
import { Product } from 'src/app/inventory/model/product';
import { AppState } from 'src/app/reducers';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserService } from '../../../../../auth/services/user.service';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { ProductService } from '../../../../../inventory/products/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { Renderer2 } from '@angular/core';
import { FeatureFlagService } from '../../../../services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../../constants/feature-flag.constants'; // Import Renderer2

@Component({
  selector: 'rw-delete-bulk-products',
  templateUrl: './delete-bulk-products.component.html',
  styleUrls: ['./delete-bulk-products.component.scss'],
})
export class DeleteBulkProductsComponent implements OnInit, OnDestroy {
  @Input() content: any = {};

  @Input() dialog = true;

  lng: string;

  langCss: string;

  products: Product[];

  variants: Product[];

  selectedProducts: Product[];

  selectedVariants: Product[];

  selectedVariantComposites: Product[];

  deleteMsg: string;

  $destroyed = false;

  variantComposites: any[] | undefined = [1];

  user: AuthUser;

  productName: string;

  element: HTMLCollectionOf<Element>;

  private featureFlagSubscription = null;

  isFeatureFlagEnableForIntegrations = false;

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private productService: ProductService,
    private translate: TranslateService,
    private renderer: Renderer2, // Inject Renderer2
    private featureFlagService: FeatureFlagService,

  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
    this.featureFlagService
      .isEnabled(FeatureFlagEnum.IntegrationsV2)
      .subscribe((integrationsV2) => {
        this.isFeatureFlagEnableForIntegrations = integrationsV2;
      });
  }

  ngOnInit(): void {
    this.loadContent();
    this.element = document.getElementsByClassName('modal-content');
    if (this.element.length) {
      this.renderer.addClass(this.element[0], 'mt-150'); // Use Renderer2
    }
  }

  loadContent() {
    if (this.dialog) {
      const config = <DynamicDialogConfig>(
        this.injector.get(DynamicDialogConfig)
      );
      if (config.data && config.data.content) {
        this.content = config.data.content;
      }
    }
    this.products = this.content.products;
    this.requestProductReference(this.content.products.map((p) => p.productId));
    this.selectedProducts = this.products.slice();
    this.userService
      .getUser()
      .pipe(
        take(1),
        tap((user) => {
          this.user = user;
        }),
        takeWhile(() => !this.$destroyed),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.$destroyed = true;
    if (this.element.length) {
      this.renderer.removeClass(this.element[0], 'mt-150'); // Use Renderer2
    }
  }
  requestProductReference(productId: number[]) {
    const subscriptionRefs = this.productService
      .getBulkProductReferences(productId)
      .subscribe((res) => {
        const { VariantToComposites } = res;
        this.variantComposites = VariantToComposites;
        this.selectedVariantComposites = this.content.products.filter((p) =>
          VariantToComposites.find(
            (v) =>
              v.ProductVariant.productId === p.productId &&
              v.Product.VariantToComposites.length === 1,
          ),
        );
        const name = this.variantComposites[0]?.Product?.name;
        this.productName = name;
        this.deleteMsg = this.translate.instant('deleteCompositeWithSingle');
      });
  }

  onChangeProduct(id: string) {
    const name = this.variantComposites.find((d) => d.productVariantId === +id)
      .Product.name;
    this.deleteMsg = this.translate.instant('deleteCompositeWithSingle', {
      Product: name,
    });
  }

  onDelete() {
    // this.selectedVariants?.forEach((variant: any, index: number) => {
    //   this.productService.deleteVariantByProductId(variant.productId, variant.id)
    //     .subscribe((res) => {
    //       if ((this.selectedProducts?.length === 0) && res.success) {
    //         if (index === 0) {
    //           this.toastService.success(this.translate.instant('The products have been deleted successfully'));
    //         }
    //         if (index === this.selectedVariants.length - 1) {
    //           this.content.cancelFunction();
    //           this.content.refreshFunction();
    //         }
    //       }
    //     });
    // });
    if (this.selectedProducts?.length > 0) {
      this.content.deleteFunction(this.selectedProducts);
    } else {
      this.content.cancelFunction();
      this.content.refreshFunction();
    }
  }
}
